body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.date_full .react-datepicker-wrapper, .date_full .react-datepicker-wrapper * {
  width: 100%;
}

.date_full .react-datepicker--time-only .react-datepicker__time-container,
.date_full .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px
}

.date_full .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 15px;
  padding: 5px 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.leaflet-div-icon{
  background:none;
  border: none;

}
a.leaflet-popup-close-button {
  padding: 8px !important; 
  margin: 1px 10px !important;
  color:black !important
}

.multi-edit-date .MuiFormControl-root {
  width: 100%;
}